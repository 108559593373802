import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Airline} from './airline.model';
import {Observable} from 'rxjs';

const url = 'http://api.ops.leyrernet.at';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private  httpClient: HttpClient) {

  }

  getData(): Observable<Airline[]> {
    return this.httpClient.get<Airline[]>(url);
  }
}
