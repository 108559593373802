import {Component, OnInit} from '@angular/core';
import {ApiService} from './api.service';
import {Airline} from './airline.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'airlines';
    lines: Airline[];
    filteredLines: Airline[];

    nonEmptyIATA: boolean;
    nonEmptyICAO: boolean;
    nonEmptyURL: boolean;

    constructor(private service: ApiService) {}

    ngOnInit(): void {
      this.filteredLines = [];
      this.loadData();
    }

    loadData() {
      this.service.getData().subscribe((data: Airline[]) => {
        this.lines = data;
        this.filteredLines = data;
        console.log(this.lines);
      });
    }

    filterData() {
      let tempLines = this.lines;

      if (this.nonEmptyIATA) {
            tempLines = tempLines.filter(item => item.IATA !== '');
        }
      if (this.nonEmptyICAO) {
            tempLines = tempLines.filter(item => item.ICAO !== '');
        }
      if (this.nonEmptyURL) {
            tempLines = tempLines.filter(item => item.URL !== '');
        }
      this.filteredLines = tempLines;
    }
}
